// src/components/CustomDonutChart.js
import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Label,
  Tooltip,
} from "recharts";

const data = [
  { name: "Section A", value: 30, color: "#FF5733" },
  { name: "Section B", value: 20, color: "#33FF57" },
  { name: "Section C", value: 25, color: "#5733FF" },
  { name: "Section D", value: 15, color: "#FF00FF" },
];

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload; // Get the entire data object
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#d3d3d3",
          padding: "4px 12px",
          borderRadius: "12px",
        }}
      >
        {active && (
          <>
            <p>{data.title}</p>
            <p>{data.value} </p>
          </>
        )}
      </div>
    );
  }

  return null;
};

const CustomDonutChart = ({ propData }) => {
  // const totalValue = data.reduce((acc, entry) => acc + entry.value, 0);

  return (
    <ResponsiveContainer width="100%" height={240}>
      <PieChart>
        <Pie
          data={propData}
          dataKey="value"
          cx="50%"
          cy="40%"
          outerRadius="80%"
          innerRadius="50%"
          fill="#8884d8"
          paddingAngle={0}
          stroke="color: '#808080'"
        >
          {propData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
          <Label
            // value={`${(totalValue / data.length).toFixed(2)}%`}
            value={"100%"}
            position="center"
            fill="#000"
            fontSize={20}
          />
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default CustomDonutChart;
