import { useEffect, useState } from "react";
import horizontalChartIcon from "../../assets/behaviour/horizontalChartIcon.svg";
import genderIcon from "../../assets/home/gender-icon.svg";
import purchaseIcon from "../../assets/home/purchase-icon.svg";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import HorizontalBarChart from "../../components/horizontalBarChart";
import fetchSurveyData from "../../services";
import styles from "../../styles/pages/behaviour/index.module.css";

const PreferencesRowOne = ({ selectedFilters }) => {
  const [sunscreenImportanceData, setSunscreenImportanceData] = useState([]);
  const [possibleSpendData, setPossibleSpendData] = useState([]);
  const [sunscreenTypeData, setSunscreenTypeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData(selectedFilters);
        console.log("response data", responseData);

        if (Array.isArray(responseData) && responseData.length > 0) {
          const sunscreenImportanceCounts = {};
          const possibleSpendCounts = {};
          const sunscreenTypeCounts = {};

          responseData.forEach((entry) => {
            const { sunscreen_importance, possible_spend, sunscreen_type } =
              entry;
            if (sunscreen_importance)
              sunscreenImportanceCounts[sunscreen_importance] =
                (sunscreenImportanceCounts[sunscreen_importance] || 0) + 1;
            if (possible_spend)
              possibleSpendCounts[possible_spend] =
                (possibleSpendCounts[possible_spend] || 0) + 1;
            if (sunscreen_type)
              sunscreenTypeCounts[sunscreen_type] =
                (sunscreenTypeCounts[sunscreen_type] || 0) + 1;
          });

          const processAndSetColor = (data, colors) => {
            return Object.entries(data)
              .filter(([name, value]) => name !== undefined)
              .map(([name, value], index) => ({
                name,
                value,
              }))
              .sort((a, b) => b.value - a.value)
              .slice(0, 5)
              .map(({ name, value }, index) => ({
                name,
                value,
                fill: colors[index] || "#A1A1AA",
              }));
          };

          const sunscreenImportanceData = Object.entries(
            sunscreenImportanceCounts
          ).map(([name, value]) => ({ name, value }));
          const sortedSunscreenImportanceArray = sunscreenImportanceData
            .sort((a, b) => b.value - a.value)
            .slice(0, 6);
          setSunscreenImportanceData(sortedSunscreenImportanceArray);

          const possibleSpendData = Object.entries(possibleSpendCounts).map(
            ([name, value]) => ({ name, value })
          );
          const sortedPossibleSpendArray = possibleSpendData
            .sort((a, b) => b.value - a.value)
            .slice(0, 4);
          setPossibleSpendData(sortedPossibleSpendArray);

          const sunscreenTypeData = processAndSetColor(sunscreenTypeCounts, [
            "#491158",
            "#641D71",
            "#81338A",
            "#C96ACE",
            "#F09FEF",
          ]);
          setSunscreenTypeData(sunscreenTypeData);
        } else {
          console.log("No survey data found or empty response.");
        }
      } catch (error) {
        console.error("Error fetching survey data:", error);
      }
    };

    fetchData();
  }, [selectedFilters]);

  return (
    <>
      <Section
        width={38}
        heading={
          <div className={styles.heading}>
            <img src={horizontalChartIcon} alt="" />
            <p>How important is sunscreen for you, in your skincare routine?</p>
            <div className={styles.count} style={{ marginLeft: "0px" }}>
              Count: <b>314</b>
            </div>
          </div>
        }
        content={
          <div style={{ display: "flex" }}>
            {" "}
            <div
              className={styles.navbar}
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "5px",
                marginLeft: "30px",
              }}
            >
              Count:{" "}
              {sunscreenImportanceData.map((item, index) => (
                <span
                  key={index}
                  style={{
                    marginBottom: "10px",
                    marginTop: "6px",
                    marginLeft: "15px",
                  }}
                >
                  {item.value}
                </span>
              ))}
            </div>
            {sunscreenImportanceData.length > 0 ? (
              <HorizontalBarChart
                data={sunscreenImportanceData}
                truncateYAxisLength={14}
              />
            ) : (
              <div>No Data Found</div>
            )}
          </div>
        }
      />

      <Section
        width={30}
        heading={
          <div className={styles.heading}>
            <img src={purchaseIcon} alt="" />
            <p>Which type of sunscreen do you like?</p>
            <div className={styles.count} style={{ marginLeft: "0px" }}>
              Count: <b>314</b>
            </div>
          </div>
        }
        content={
          sunscreenTypeData.length > 0 ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
                overflow: "hidden",
                width: "95%",
                marginLeft: "10px",
              }}
            >
              {sunscreenTypeData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    height: "42px",
                    backgroundColor: item.fill,
                    borderRadius: "8px",
                    marginBottom: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 16px 0px 10px",
                    overflow: "hidden",
                  }}
                >
                  <p style={{ fontWeight: "bold", color: "#FFFFFF" }}>
                    {item.title || item.name}
                  </p>
                  <p
                    style={{
                      borderRadius: "6px",
                      backgroundColor: "#FFF",
                      padding: "5px",
                      border: "1px solid #3F4E99",
                      textAlign: "center",
                      fontWeight: "bold",
                      overflow: "hidden",
                    }}
                  >
                    {item.value}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <div>No data found</div>
          )
        }
      />

      <Section
        heading={
          <div className={styles.heading}>
            <img src={genderIcon} alt="" />
            <p>How much would you spend on sunscreen?</p>
            <div className={styles.count} style={{ marginLeft: "0px" }}>
              Count: <b>314</b>
            </div>
          </div>
        }
        content={
          <CompareBarChart
            data={possibleSpendData}
            yAxisWidth={20}
            showLegend={false}
          />
        }
        navbar={
          <div
            className={styles.navbar}
            style={{ marginLeft: "10px", marginRight: "5px" }}
          >
            Count:{" "}
            {possibleSpendData.map((item, index) => (
              <span key={index} style={{ margin: "0px 25px 10px 25px" }}>
                {item.value}
              </span>
            ))}
          </div>
        }
        width={30}
      />
    </>
  );
};

export default PreferencesRowOne;
