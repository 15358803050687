import { useState } from "react";
import styles from "../styles/components/MultiFilter.module.css";
import miniFilterIcon from "../assets/home/mini-filter-icon.svg";

const MultiFilter = ({ handleUpdateFilter }) => {
  const [selectedFilters, setSelectedFilters] = useState({
    ageFilter: [],
    genderFilter: [],
    cityFilter: [],
    skinTypeFilter: [],
    hairTypeFilter: [],
  });

  // Define metropolitan and non-metropolitan cities internally
  const metropolitanCities = [
    "Delhi",
    "Mumbai",
    "Chennai",
    "Kolkata",
    "Bangalore",
    "Hyderabad",
    "Ahmedabad",
    "Pune",
    "Surat",
    "Jaipur",
    "Lucknow",
    "Kanpur",
  ];

  const handleFilterToggle = (filterType, filterValue) => {
    const newSelectedFilters = { ...selectedFilters };

    const filterIndex = newSelectedFilters[filterType].indexOf(filterValue);

    if (filterIndex !== -1) {
      newSelectedFilters[filterType].splice(filterIndex, 1);
    } else {
      newSelectedFilters[filterType].push(filterValue);
    }

    setSelectedFilters(newSelectedFilters);
    handleUpdateFilter(newSelectedFilters);
  };

  const renderFilterCapsules = (filterType, filterOptions) => {
    return filterOptions.map((filter) => (
      <p
        key={filter}
        className={`${styles.filterCapsule} ${
          selectedFilters[filterType].includes(filter)
            ? styles.activeFilter
            : ""
        }`}
        onClick={() => handleFilterToggle(filterType, filter)}
      >
        {filter}
      </p>
    ));
  };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "15px",
        zIndex: 99,
        display: "flex",
        flexWrap: "wrap",
        transition: "right 0.4s ease-in-out",
      }}
      className={styles.container}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: "8px" }}>
          <img src={miniFilterIcon} alt="" />
          <p className={styles.filterTitle}>Filter</p>
        </div>
        <p
          style={{
            cursor: "pointer",
            paddingRight: "8px",
            opacity: "0.7",
          }}
        ></p>
      </div>
      <div>
        <div>
          <p className={styles.filterTitle}>Age</p>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {renderFilterCapsules("ageFilter", [
              "16-20",
              "21-25",
              "25-30",
              "26-30",
              "31-35",
            ])}
          </div>
        </div>

        <div>
          <p className={styles.filterTitle}>Gender</p>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
            {renderFilterCapsules("genderFilter", ["Female", "Male"])}
          </div>
        </div>
      </div>

      <div>
        <p className={styles.filterTitle}>Location</p>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          <p
            className={`${styles.filterCapsule} ${
              selectedFilters.cityFilter.includes("Metropolitan")
                ? styles.activeFilter
                : ""
            }`}
            onClick={() => handleFilterToggle("cityFilter", "Metropolitan")}
          >
            Metropolitan
          </p>
          <p
            className={`${styles.filterCapsule} ${
              selectedFilters.cityFilter.includes("Non-Metropolitan")
                ? styles.activeFilter
                : ""
            }`}
            onClick={() => handleFilterToggle("cityFilter", "Non-Metropolitan")}
          >
            Non-Metropolitan
          </p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          gap: "24px",
          alignItems: "center",
          marginTop: "24px",
        }}
      >
        <div
          style={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "21px",
            color: "#52525B",
          }}
        >
          {
            [
              ...selectedFilters.ageFilter,
              ...selectedFilters.genderFilter,
              ...selectedFilters.cityFilter,
            ].length
          }{" "}
          Filters
        </div>
        <div
          style={{
            backgroundColor: "#18181B",
            borderRadius: "100px",
            // padding: "8px 16px",
            color: "#FFF",
            cursor: "pointer",
          }}
          //   onClick={() => setOpenFilter(false)}
        >
          {/* Add Filters */}
        </div>
      </div>
    </div>
  );
};

export default MultiFilter;
