const Section = ({ width, heading, content, height, navbar }) => {
  const sectionStyle = {
    width: `${width ? width : 30}%`,
    height: `${height ? height : 320}px`,
    backgroundColor: "#fff",
    padding: "0px 0px",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    border: "1px solid #E4E4E7",
    transition: "transform 0.3s ease", // Add transition property

    ":hover": {
      transform: "scale(0.96)", // Zoom effect on hover
    },
  };
  return (
    <div style={sectionStyle}>
      {heading}
      {content}
      {navbar}
    </div>
  );
};

export default Section;
