import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div className="custom-tooltip">
        {active && (
          <>
            <p
              style={{ fontSize: "15px", fontWeight: 400, opacity: 0.7 }}
            >{`Name: ${data.name}`}</p>
            <p
              style={{ fontSize: "15px", fontWeight: 400, opacity: 0.7 }}
            >{`Value: ${data.value}`}</p>
          </>
        )}
      </div>
    );
  }

  return null;
};

const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      let lines = [];
      for (let i = 0; i < label.length; i += maxLength) {
        lines.push(label.substring(i, i + maxLength));
      }
      return lines.map((line, index) => (
        <tspan x="0" dy={index > 0 ? "1.2em" : 0} key={index}>
          {line}
        </tspan>
      ));
    }
    return label;
  };
  

const CustomXAxisTick = (props) => {
  const { x, y, payload } = props;
  const truncatedLabel = truncateLabel(payload.value, 15);
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={12} fontSize={12} textAnchor="middle" fill="#666">
        {truncatedLabel}
      </text>
    </g>
  );
};

const CustomYAxisTick = (props) => {
  const { x, y, payload } = props;
  const truncatedLabel = truncateLabel(payload.value, 10);

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} fontSize={11} textAnchor="end" fill="#666">
        {truncatedLabel}
      </text>
    </g>
  );
};  

const HorizontalBarChart = ({ data, truncateXAxisLength, truncateYAxisLength }) => {
  const customXAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, truncateXAxisLength || 15);
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={12} fontSize={12} textAnchor="middle" fill="#666">
          {truncatedLabel}
        </text>
      </g>
    );
  };

  const customYAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, truncateYAxisLength || 23);

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} fontSize={11} textAnchor="end" fill="#666">
          {truncatedLabel}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        layout="vertical"
        data={data}
        margin={{ top: 10, right: 15, left: 100, bottom: 5 }}
      >
        <CartesianGrid stroke="#f5f5f6" />
        <XAxis type="number" tick={customXAxisTick} />
        <YAxis
          type="category"
          width={22}
          tick={customYAxisTick}
          interval={0}
          dataKey="name"
        />
        <Tooltip content={<CustomTooltip />} />
        <Bar dataKey="value" fill="#A1A1AA" radius={[0, 10, 10, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBarChart;
