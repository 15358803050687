// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileNav_container__skuTe {
  background-color: #fff;
  left: 0;
  padding: 8px 16px;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  gap: 16px;
  z-index: 999;
}

.MobileNav_sidebarButton__6cCIq {
  display: flex;
  align-items: center;
  background-color: #faf9f9;
  padding: 12px;
  border-radius: 12px;
  gap: 10px;
  font-size: 16px;
  font-family: "Urbanist";
}

.MobileNav_activeButton__E4lxn {
  background-color: #591B5F;
}

.MobileNav_text__d9xSd {
  margin: 0px;
}

.MobileNav_textActive__f2\\+BO {
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/MobileNav.module.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,OAAO;EACP,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb","sourcesContent":[".container {\n  background-color: #fff;\n  left: 0;\n  padding: 8px 16px;\n  width: 100%;\n  position: fixed;\n  display: flex;\n  align-items: center;\n  gap: 16px;\n  z-index: 999;\n}\n\n.sidebarButton {\n  display: flex;\n  align-items: center;\n  background-color: #faf9f9;\n  padding: 12px;\n  border-radius: 12px;\n  gap: 10px;\n  font-size: 16px;\n  font-family: \"Urbanist\";\n}\n\n.activeButton {\n  background-color: #591B5F;\n}\n\n.text {\n  margin: 0px;\n}\n\n.textActive {\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MobileNav_container__skuTe`,
	"sidebarButton": `MobileNav_sidebarButton__6cCIq`,
	"activeButton": `MobileNav_activeButton__E4lxn`,
	"text": `MobileNav_text__d9xSd`,
	"textActive": `MobileNav_textActive__f2+BO`
};
export default ___CSS_LOADER_EXPORT___;
