// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yellow-primary {
  background-color: #fcf36b;
}

.flex-row {
  display: flex;
}

* {
  box-sizing: border-box !important;
}
/* common */
@media (max-width: 1200px) {
  .hide-in__mobile {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hide-in__desktop {
    display: none !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAC3B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iCAAiC;AACnC;AACA,WAAW;AACX;EACE;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;AACF","sourcesContent":[".yellow-primary {\n  background-color: #fcf36b;\n}\n\n.flex-row {\n  display: flex;\n}\n\n* {\n  box-sizing: border-box !important;\n}\n/* common */\n@media (max-width: 1200px) {\n  .hide-in__mobile {\n    display: none !important;\n  }\n}\n\n@media (min-width: 1200px) {\n  .hide-in__desktop {\n    display: none !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
