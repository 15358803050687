import { useState, useEffect } from "react";
import styles from "../../styles/pages/preferences/index.module.css";
import CompareBarChart from "../../components/CompareBarChart";
import chartIcon from "../../assets/behaviour/chart-icon.svg";
import Section from "../../components/BrandComparisonSection";

const naturalsData = require("../../data/naturalsData.json.json");
const otherSunscreenProblemData = require("../../data/preferences/otherProblemData.json");

const PreferencesRowThree = ({ selectedFilters }) => {

  const [problemsData, setProblemsData] = useState([]);
  const metropolitanCities = [
    "Delhi",
    "Mumbai",
    "Chennai",
    "Kolkata",
    "Bangalore",
    "Hyderabad",
    "Ahmedabad",
    "Pune",
    "Surat",
    "Jaipur",
    "Lucknow",
    "Kanpur",
  ];
  
  useEffect(() => {
    const processProblemsData = () => {
      const problemsCounts = {};
      let filteredData = naturalsData;
    
      if (selectedFilters.ageFilter.length > 0) {
        filteredData = filteredData.filter((entry) =>
          selectedFilters.ageFilter.includes(entry.age)
        );
      }
    
      if (selectedFilters.genderFilter.length > 0) {
        filteredData = filteredData.filter((entry) =>
          selectedFilters.genderFilter.includes(entry.gender)
        );
      }

if (selectedFilters.cityFilter.length > 0) {
        if (selectedFilters.cityFilter.includes("Metropolitan")) {
          filteredData = filteredData.filter((entry) =>
            metropolitanCities.includes(entry.city)
          );
        } else if (selectedFilters.cityFilter.includes("Non-Metropolitan")) {
          filteredData = filteredData.filter(
            (entry) => !metropolitanCities.includes(entry.city)
          );
        }
      }
    
      filteredData.forEach((entry) => {
        const { problems } = entry;
        if (problems && problems !== "") {
          const problemValues = problems.split(/,\s*(?![^()]*\))/);
          problemValues.forEach((value) => {
            const trimmedValue = value.trim();
            if (trimmedValue !== "") {
              if (problemsCounts[trimmedValue]) {
                problemsCounts[trimmedValue]++;
              } else {
                problemsCounts[trimmedValue] = 1;
              }
            }
          });
        }
      });
    
      const problemsArray = Object.entries(problemsCounts)
        .map(([name, value]) => ({ name: name || "Unknown", value }))
        .filter((entry) => entry.name !== "");
    
      const sortedProblemsArray = problemsArray
        .sort((a, b) => b.value - a.value)
        .slice(0, 9);
    
      setProblemsData(sortedProblemsArray);
    };
    
    processProblemsData();
  }, [selectedFilters]);

  return (
    <>
      <Section
        height={320}
        heading={
          <div className={styles.heading}>
            <img src={chartIcon} alt="" />
            <p>
              What are the biggest problems you have with using
              sunscreen?(Others)
            </p>
            <div className={styles.count} style={{ marginLeft: "600px" }}>
              Count: <b>50</b>
            </div>
          </div>
        }
        content={
          <CompareBarChart
            data={problemsData}
            yAxisWidth={20}
            showLegend={false}
          />
        }
        navbar={
          <div
            className={styles.navbar}
            style={{ marginLeft: "10px", marginRight: "5px" }}
          >
            Count:{" "}
            {problemsData.map((item, index) => (
              <span key={index} style={{ margin: "0px 75px 20px 95px" }}>
                {item.value}
              </span>
            ))}
          </div>
        }
        width={100}
      />
    </>
  );
};

export default PreferencesRowThree;
