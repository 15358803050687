import CompareBarChart from "../../components/CompareBarChart";
import chartIcon from "../../assets/behaviour/chart-icon.svg";
import chartTilted from "../../assets/behaviour/chart-tilted-icon.svg";
import Section from "../../components/BrandComparisonSection";
import ComparisonStackedChart from "../../components/StackedBarChart";
import styles from "../../styles/pages/behaviour/MobileComponent.module.css";
import LineCompareChart from "../../components/CompareLineChart";
import BarAndLine from "../../components/barAndLineChart";
import shuffleArray from "../../utils/shuffle";
const top5cities = require("../../data/compare/top5cities.json");
const ageData = require("../../data/compare/age.json");
// const topSkinTypes = require("../../data/compare/topSkinTypes.json");

const topHairTypes = require("../../data/compare/tophairTypes.json");

const ComparisonMobile = () => {
  return (
    <div className={`${styles.mobileContentContainer} hide-in__desktop`}>
      <Section
        heading={
          <div style={{ display: "flex", gap: "12px" }}>
            <img src={chartIcon} alt="" />
            <p>Top Skin Types</p>
          </div>
        }
        content={
          <BarAndLine
            // data={topSkinTypes}
            yAxisWidth={1}
            tooltipTrigger="click"
          />
        }
        width={100}
      />
      <Section
        heading={
          <div style={{ display: "flex", gap: "12px" }}>
            <img src={chartTilted} alt="" />
            <p>Top Hairtypes</p>
          </div>
        }
        content={
          <ComparisonStackedChart
            data={topHairTypes}
            yAxisWidth={8}
            tooltipTrigger="click"
          />
        }
        width={100}
      />
      <Section
        heading={
          <div style={{ display: "flex", gap: "12px" }}>
            <img src={chartIcon} alt="" />
            <p>Top 5 Cities</p>
          </div>
        }
        content={
          <CompareBarChart
            data={top5cities}
            yAxisWidth={16}
            tooltipTrigger="click"
          />
        }
        width={100}
      />
      <Section
        heading={
          <div style={{ display: "flex", gap: "12px" }}>
            <img src={chartIcon} alt="" />
            <p>Age</p>
          </div>
        }
        content={
          <LineCompareChart
            data={shuffleArray(ageData)}
            yAxisWidth={10}
            tooltipTrigger="click"
          />
        }
        width={100}
      />
    </div>
  );
};

export default ComparisonMobile;
