import { Button, Drawer, Space } from "antd";
import React, { useEffect, useState } from "react";
import chartIcon from "../../assets/behaviour/chart-icon.svg";
import doughnutIcon from "../../assets/home/age-icon.svg";
import filterIcon from "../../assets/home/filter-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import MobileNav from "../../components/MobileNav";
import MultiFilter from "../../components/MultiFilter";
import Sidebar from "../../components/Sidebar";
import CustomDonutChart from "../../components/doughnutChart";
import fetchSurveyData from "../../services";
import styles from "../../styles/pages/sunscreen/index.module.css";

const Sunscreen = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };

  const [sunstickPreferenceData, setSunstickPreferenceData] = useState([]);
  const [primaryReasonTintedData, setPrimaryReasonTintedData] = useState([]);
  const [knewSunscreenData, setKnewSunscreenData] = useState([]);
  const [usedTintedData, setUsedTintedData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    ageFilter: [],
    genderFilter: [],
    cityFilter: [],
  });

  const fetchData = async () => {
    try {
      const responseData = await fetchSurveyData(selectedFilters);

      if (Array.isArray(responseData) && responseData.length > 0) {
        const processCountData = (responseData, key, addColor = false) => {
          const counts = {};
          responseData.forEach((entry) => {
            const value = entry[key];
            if (value && value.trim() !== "") {
              counts[value] = (counts[value] || 0) + 1;
            }
          });
          return addColor
            ? Object.entries(counts)
                .filter(([name]) => name !== undefined)
                .map(([name, value]) => ({
                  name,
                  value,
                  fill: name === "No" ? "#813389" : "#52525B",
                }))
                .sort((a, b) => b.value - a.value)
                .slice(0, 5)
            : Object.entries(counts)
                .filter(([name]) => name !== undefined)
                .map(([name, value]) => ({ name, value }))
                .sort((a, b) => b.value - a.value)
                .slice(0, 5);
        };

        const preferSunstickCounts = processCountData(
          responseData,
          "prefer_sunstick"
        );
        setSunstickPreferenceData(preferSunstickCounts);

        const knowSunstickCounts = processCountData(
          responseData,
          "know_sunstick",
          true
        );
        setKnewSunscreenData(knowSunstickCounts);

        const usedTintedCounts = processCountData(
          responseData,
          "used_tinted",
          true
        );
        setUsedTintedData(usedTintedCounts);

        const reasonTintedCounts = processCountData(
          responseData,
          "primary_reason_tinted"
        );
        setPrimaryReasonTintedData(reasonTintedCounts);
      } else {
        console.log("No survey data found or empty response.");
      }
    } catch (error) {
      console.error("Error fetching survey data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [selectedFilters]);

  const handleUpdateFilter = (filters) => {
    setSelectedFilters(filters);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.container}>
      <Sidebar handleChange={handleChange} />
      <MobileNav />
      <div className={styles.bodyContainer}>
        <div className={styles.subBodyContainer}>
          {page === 1 && (
            <div className="hide-in__mobile">
              <div
                className={styles.topBar}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1 }}>
                  <h2 style={{ display: "inline-block" }}>
                    Types of Sunscreen
                  </h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={showDrawer}
                >
                  <img src={filterIcon} height={35} alt="" />
                </div>
              </div>

              <Drawer
                placement="right"
                width="auto"
                onClose={onClose}
                open={open}
                extra={
                  <Space>
                    <Button onClick={onClose}>Close</Button>
                    <Button type="primary">Apply Filter</Button>
                  </Space>
                }
              >
                <MultiFilter handleUpdateFilter={handleUpdateFilter} />
              </Drawer>

              <div className={styles.brandsSelectContainer}>
                <Section
                  width={42}
                  height={320}
                  heading={
                    <div className={styles.heading}>
                      <img src={doughnutIcon} alt="" />
                      <p>
                        Did you know, sunscreen comes in a stick called
                        sunstick?
                      </p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "0px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    knewSunscreenData.length > 0 ? (
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "65%", marginTop: "30px" }}>
                          <CustomDonutChart propData={knewSunscreenData} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: "6px",
                            width: "65%",
                            marginTop: "80px",
                          }}
                        >
                          {knewSunscreenData.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                height: "50px",
                                width: "80%",
                                border: "1px solid #F2F2F2",
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                padding: "6px",
                                borderRadius: "8px",
                              }}
                            >
                              <div
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  borderRadius: "50%",
                                  backgroundColor: `${item.fill}`,
                                }}
                              ></div>
                              <div>
                                {item.title || item.name}
                                <span
                                  style={{
                                    marginRight: "0px",
                                    marginLeft: "90px",
                                  }}
                                >
                                  {item.value}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div>no data</div>
                    )
                  }
                />

                <Section
                  width={58}
                  height={320}
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>If yes, why do you prefer sun sticks?</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "305px", alignContent: "center" }}
                      >
                        Yes : <b>199</b>
                      </div>
                    </div>
                  }
                  content={
                    sunstickPreferenceData.length > 0 ? (
                      <SimpleBarChart
                        data={sunstickPreferenceData}
                        truncateLength={23}
                      />
                    ) : (
                      <div>No Data Found</div>
                    )
                  }
                  navbar={
                    <div
                      className={styles.navbar}
                      style={{ marginRight: "10px" }}
                    >
                      Count:{" "}
                      {sunstickPreferenceData.map((item, index) => (
                        <span
                          key={index}
                          style={{ marginLeft: "40px", marginRight: "67px" }}
                        >
                          {item.value}
                        </span>
                      ))}
                    </div>
                  }
                />
              </div>

              <div className={styles.brandsSelectContainer}>
                <Section
                  width={37}
                  height={320}
                  heading={
                    <div className={styles.heading}>
                      <img src={doughnutIcon} alt="" />
                      <p>Have you ever used a tinted sunscreen?</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "40px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    usedTintedData.length > 0 ? (
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "65%", marginTop: "30px" }}>
                          <CustomDonutChart propData={usedTintedData} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: "6px",
                            width: "65%",
                            marginTop: "80px",
                          }}
                        >
                          {usedTintedData.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                height: "50px",
                                width: "80%",
                                border: "1px solid #F2F2F2",
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                padding: "6px",
                                borderRadius: "8px",
                              }}
                            >
                              <div
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  borderRadius: "50%",
                                  backgroundColor: `${item.fill}`,
                                }}
                              ></div>
                              <div>
                                {item.title || item.name}
                                <span
                                  style={{
                                    marginRight: "0px",
                                    marginLeft: "70px",
                                  }}
                                >
                                  {item.value}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div>no data</div>
                    )
                  }
                />

                <Section
                  width={63}
                  height={320}
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>
                        If yes, what is the primary reason you use a tinted
                        sunscreen?
                      </p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "220px", alignContent: "center" }}
                      >
                        Yes : <b>115</b>
                      </div>
                    </div>
                  }
                  content={
                    primaryReasonTintedData.length > 0 ? (
                      <SimpleBarChart
                        data={primaryReasonTintedData}
                        truncateLength={18}
                      />
                    ) : (
                      <div>No Data Found</div>
                    )
                  }
                  navbar={
                    <div className={styles.navbar}>
                      Count:{" "}
                      {primaryReasonTintedData.map((item, index) => (
                        <span
                          key={index}
                          style={{
                            position: "relative",
                            marginLeft: "45px",
                            marginRight: "80px",
                          }}
                        >
                          {item.value}
                        </span>
                      ))}
                    </div>
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sunscreen;
