import newLogo from "../assets/home/Naturals_logo.svg";
import ham from "../assets/common/hamburg-icon.svg";
import gif from "../assets/common/mobile-gif.gif";
import styles from "../styles/components/MobileNav.module.css";
import { Button, Drawer, Radio, Space } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HairOilIcon from "../assets/common/dynamic-svgs/HairOil";
// import BrandBarsIcon from "../assets/common/dynamic-svgs/BrandBarsIcon";
import { act } from "react-dom/test-utils";
import ResIcon from "../assets/common/dynamic-svgs/ResourseIcon";
import ComparisonIcon from "../assets/common/dynamic-svgs/ComparisonIcon";
const MobileNav = () => {
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState("left");
  const [active, setActive] = useState(null);
  const navigate = useNavigate();
  const showDrawer = () => {
    setOpen(true);
  };
  const onChange = (e) => {
    setPlacement(e.target.value);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (window.location.pathname === "/") {
      setActive(1);
    } else if (window.location.pathname === "/hairoil") {
      setActive(2);
    } else if (window.location.pathname === "/resources") {
      setActive(3);
    } else if (window.location.pathname === "/brand-comparison") {
      setActive(4);
    }
  });
  return (
    <div className={`hide-in__desktop ${styles.container}`}>
      <img src={ham} alt="" onClick={() => setOpen(true)} />
      <img src={newLogo} height={40} alt="" onClick={() => navigate("/")} />

      <Drawer
        placement={placement}
        width="auto"
        onClose={onClose}
        open={open}
        maskClosable={true}
        closeIcon={false}
      >
        <div style={{ borderBottom: "1px solid #E4E4E7" }}>
          <img src={newLogo} height={48} alt="" onClick={onClose} />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "24px",
            gap: "10px",
          }}
        >
          <div
            className={`${styles.sidebarButton} ${
              active === 1 ? styles.activeButton : ""
            }`}
            onClick={() => {
              onClose();
              navigate("/");
            }}
          >
            {/* <BrandBarsIcon fill={active === 1 && "#FFF"} /> */}
            <p
              className={`${styles.text} ${
                active === 1 ? styles.textActive : ""
              }`}
            >
              Brand Analysis
            </p>
          </div>
          <div
            className={`${styles.sidebarButton} ${
              active === 2 ? styles.activeButton : ""
            }`}
            onClick={() => {
              onClose();
              navigate("/hairoil");
            }}
          >
            <HairOilIcon fill={active === 2 ? "#FFF" : "#52525B"} />
            <p
              className={`${styles.text} ${
                active === 2 ? styles.textActive : ""
              }`}
            >
              Hair-oil
            </p>
          </div>
          <div
            className={`${styles.sidebarButton} ${
              active === 3 ? styles.activeButton : ""
            }`}
            onClick={() => {
              onClose();
              navigate("/resources");
            }}
          >
            <ResIcon fill={active === 3 && "#FFF"} />
            <p
              className={`${styles.text} ${
                active === 3 ? styles.textActive : ""
              }`}
            >
              Resources
            </p>
          </div>
          <div
            className={`${styles.sidebarButton} ${
              active === 4 ? styles.activeButton : ""
            }`}
            onClick={() => {
              onClose();
              navigate("/brand-comparison");
            }}
          >
            <ComparisonIcon fill={active === 4 && "#FFF"} />
            <p
              className={`${styles.text} ${
                active === 4 ? styles.textActive : ""
              }`}
            >
              Brand Comparison
            </p>
          </div>
        </div>

        <div style={{ position: "absolute", bottom: 0 }}>
          <img src={gif} height={150} alt="" onClick={onClose} />
          <p
            style={{
              color: "#52525B",
              fontFamily: "urbanist",
              fontSize: "14px",
              lineHeight: "20px",
              margin: "0px 0px 32px 0px",
              fontWeight: 600,
            }}
          >
            Kindly open the Dashboard in Desktop version for full specs
          </p>
        </div>
      </Drawer>
    </div>
  );
};

export default MobileNav;
