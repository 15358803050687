// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Sidebar_selectedMenuItem__VJhQL {
  background-color: #591B5F !important;
  color: #fff !important;
}

.Sidebar_menuItem__Bntw9 {
  display: flex;
  align-items: center;
}

.Sidebar_subMenu__WJxlN {
  padding-left: 5px;
}

.Sidebar_menuItemTitle__zHrh8 {
  display: flex;
  align-items: center;
  font-family: Urbanist;
  font-size: 16px;
  font-weight: 700;
}

.Sidebar_menuItemIcon__GKA8A {
  margin-right: 8px;
  margin-top: 15px;
}

.Sidebar_menuItemTitleBold__45oXj {
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/common/Sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;EACrB,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".selectedMenuItem {\n  background-color: #591B5F !important;\n  color: #fff !important;\n}\n\n.menuItem {\n  display: flex;\n  align-items: center;\n}\n\n.subMenu {\n  padding-left: 5px;\n}\n\n.menuItemTitle {\n  display: flex;\n  align-items: center;\n  font-family: Urbanist;\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.menuItemIcon {\n  margin-right: 8px;\n  margin-top: 15px;\n}\n\n.menuItemTitleBold {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectedMenuItem": `Sidebar_selectedMenuItem__VJhQL`,
	"menuItem": `Sidebar_menuItem__Bntw9`,
	"subMenu": `Sidebar_subMenu__WJxlN`,
	"menuItemTitle": `Sidebar_menuItemTitle__zHrh8`,
	"menuItemIcon": `Sidebar_menuItemIcon__GKA8A`,
	"menuItemTitleBold": `Sidebar_menuItemTitleBold__45oXj`
};
export default ___CSS_LOADER_EXPORT___;
