import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomLegend = (props) => {
  const { payload } = props;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
      {payload.map((entry, index) => (
        <div
          key={`legend-${index}`}
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 8,
            border: "0.8px solid lightgrey",
            padding: "2px 8px",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              width: 10,
              height: 10,
              backgroundColor: entry.color,
              borderRadius: "50%",
              marginRight: 5,
            }}
          />
          <span style={{ fontSize: 14, marginRight: 5 }}>{entry.value}</span>
        </div>
      ))}
    </div>
  );
};

const LineCompareChart = ({ data, yAxisWidth, tooltipTrigger }) => {
  const customXAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, 12); // Adjust the truncation length as needed
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fontSize={11} textAnchor="middle" fill="#666">
          {truncatedLabel}
        </text>
      </g>
    );
  };

  const CustomYAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, 18); // Adjust the truncation length as needed

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} fontSize={11} textAnchor="end" fill="#666">
          {truncatedLabel}
        </text>
      </g>
    );
  };

  const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      return label.substring(0, maxLength) + "...";
    }
    return label;
  };

  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 30,
          bottom: 20,
          left: 30,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="key" tick={customXAxisTick} interval={0} />

        <YAxis
          width={yAxisWidth ? yAxisWidth : 0}
          tick={CustomYAxisTick}
          interval={0}
        />
        <Tooltip trigger={tooltipTrigger ? tooltipTrigger : "hover"} />
        <Legend content={<CustomLegend />} />
        <Line type="monotone" dataKey="Never" stroke="#18181B" name="Never" />
        <Line
          type="monotone"
          dataKey="Visiting"
          stroke="#813389"
          name="Visiting"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineCompareChart;
