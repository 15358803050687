import { Button, Drawer, Space } from "antd";
import { useEffect, useState } from "react";
import chartIcon from "../../assets/behaviour/chart-icon.svg";
import Ranking from "../../assets/home/RankingData.png";
import doughnutIcon from "../../assets/home/age-icon.svg";
import filterIcon from "../../assets/home/filter-icon.svg";
import rankingIcon from "../../assets/home/rankingIcon.svg";
import RecommendorsIcon from "../../assets/home/recommendors-icon.svg";
import locationIcon from "../../assets/sunscreen/location-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import MobileNav from "../../components/MobileNav";
import MultiFilter from "../../components/MultiFilter";
import Sidebar from "../../components/Sidebar";
import CustomDonutChart from "../../components/doughnutChart";
import fetchSurveyData from "../../services";
import styles from "../../styles/pages/home/index.module.css";

const rankingData = require("../../data/home/rankingData.json");

const Home = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [ageData, setAgeData] = useState([]);
  const [genderData, setGenderData] = useState([]);
  const [topCityData, setTopCityData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    ageFilter: [],
    genderFilter: [],
    cityFilter: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData(selectedFilters);

        if (Array.isArray(responseData) && responseData.length > 0) {
          const ageCounts = {};
          const genderCounts = {};
          const cityCounts = {};

          responseData.forEach((entry) => {
            const { age, gender, city } = entry;
            if (age) ageCounts[age] = (ageCounts[age] || 0) + 1;
            if (gender) genderCounts[gender] = (genderCounts[gender] || 0) + 1;
            if (city) cityCounts[city] = (cityCounts[city] || 0) + 1;
          });

          const ageData = Object.entries(ageCounts)
            .filter(([name, value]) => name !== undefined)
            .map(([name, value]) => ({ name, value }));

          const sortedAgeArray = ageData.sort((a, b) => b.value - a.value);
          setAgeData(sortedAgeArray);

          const genderData = Object.entries(genderCounts)
            .filter(([name, value]) => name !== undefined)
            .map(([name, value]) => ({
              name,
              value,
              fill: name === "Female" ? "#813389" : "#52525B",
            }));
          const sortedGenderArray = genderData.sort(
            (a, b) => b.value - a.value
          );
          setGenderData(sortedGenderArray);

          const topCityData = Object.entries(cityCounts)
            .filter(([name, value]) => name !== undefined)
            .map(([name, value]) => ({ name, value }));
          const sortedCityArray = topCityData.sort((a, b) => b.value - a.value);
          setTopCityData(sortedCityArray);
        } else {
          console.log("No survey data found or empty response.");
        }
      } catch (error) {
        console.error("Error fetching survey data:", error);
      }
    };

    fetchData();
  }, [selectedFilters]);

  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };

  const handleUpdateFilter = (filters) => {
    setSelectedFilters(filters);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.container}>
      <Sidebar handleChange={handleChange} />
      <MobileNav />
      <div className={styles.bodyContainer}>
        <div className={styles.subBodyContainer}>
          {page === 1 && (
            <div className="hide-in__mobile">
              <div
                className={styles.topBar}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1 }}>
                  <h2 style={{ display: "inline-block" }}>Home</h2>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={showDrawer}
                >
                  <img src={filterIcon} height={35} alt="Filter" />
                </div>
              </div>
              <Drawer
                placement="right"
                width="auto"
                onClose={onClose}
                open={open}
                extra={
                  <Space>
                    <Button onClick={onClose}>Close</Button>
                    <Button type="primary" onClick={handleUpdateFilter}>
                      Apply Filter
                    </Button>
                  </Space>
                }
              >
                <MultiFilter handleUpdateFilter={handleUpdateFilter} />
              </Drawer>
              {/* Row one */}
              <div className={styles.brandsSelectContainer}>
                <Section
                  width={57}
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>Age</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "480px" }}
                      >
                        Count: <b>314</b>{" "}
                      </div>
                    </div>
                  }
                  content={
                    ageData.length > 0 ? (
                      <SimpleBarChart data={ageData} />
                    ) : (
                      <div>No Data Found</div>
                    )
                  }
                  navbar={
                    <div className={styles.navbar}>
                      Count:{" "}
                      {ageData.map((item, index) => (
                        <span
                          key={index}
                          style={{ marginLeft: "60px", marginRight: "40px" }}
                        >
                          {item.value}
                        </span>
                      ))}
                    </div>
                  }
                />

                <Section
                  width={43}
                  heading={
                    <div className={styles.heading}>
                      <img src={doughnutIcon} alt="" />
                      <p>Gender</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "290px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    genderData.length > 0 ? (
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "65%", marginTop: "30px" }}>
                          <CustomDonutChart propData={genderData} />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            flexWrap: "wrap",
                            gap: "6px",
                            width: "65%",
                            marginTop: "80px",
                          }}
                        >
                          {genderData.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                height: "50px",
                                width: "80%",
                                border: "1px solid #F2F2F2",
                                display: "flex",
                                flexDirection: "row",
                                gap: "10px",
                                padding: "6px",
                                borderRadius: "8px",
                              }}
                            >
                              <div
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  borderRadius: "50%",
                                  backgroundColor: `${item.fill}`,
                                }}
                              ></div>
                              <div>
                                {item.name}
                                <span
                                  style={{
                                    marginRight: "0px",
                                    marginLeft: "70px",
                                  }}
                                >
                                  {item.value}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div>no data</div>
                    )
                  }
                />
              </div>
              {/* Row two */}
              <div className={styles.brandsSelectContainer}>
                <Section
                  width={30}
                  height={560}
                  heading={
                    <div className={styles.heading}>
                      <img src={RecommendorsIcon} alt="" />
                      <p>Where do you live – Metro:</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "30px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    topCityData.length > 0 ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          height: "500px",
                          width: "95%",
                          overflow: "hidden",
                          marginTop: "10px",
                          marginLeft: "7px",
                        }}
                      >
                        {topCityData.map((item, index) => (
                          <div
                            key={index}
                            style={{
                              height: "40px",
                              backgroundColor: calculateCityColor(item.value),
                              borderRadius: "8px",
                              marginBottom: "5px",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              padding: "0px 8px 0px 10px",
                            }}
                          >
                            <div style={{ display: "flex", gap: "4px" }}>
                              <img src={locationIcon} alt="" />
                              <p style={{ color: "#FFF", fontWeight: "bold" }}>
                                {item.name}
                              </p>
                            </div>
                            <p style={{ color: "#FFF", fontWeight: "bold" }}>
                              {item.value}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>No data found</div>
                    )
                  }
                />
                <Section
                  width={70}
                  height={560}
                  heading={
                    <div className={styles.heading}>
                      <img src={rankingIcon} alt="" />
                      <p>Ranking</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "610px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <img
                          src={Ranking}
                          alt=""
                          style={{
                            width: "700px",
                            height: "480px",
                            objectFit: "cover",
                            margin: "25px 10px 10px 50px",
                            overflow: "hidden",
                          }}
                        />
                      </div>
                      <div
                        className={styles.navbar}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "5px",
                          marginLeft: "0px",
                          marginRight: "20px",
                        }}
                      >
                        Count:{" "}
                        {rankingData.map((item, index) => (
                          <span
                            key={index}
                            style={{
                              marginBottom: "20px",
                              marginTop: "20px",
                              marginLeft: "15px",
                              marginRight: "10px",
                            }}
                          >
                            {item.value}
                          </span>
                        ))}
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const calculateCityColor = (count) => {
  if (count > 80) {
    return "#F09FEF";
  } else if (count > 60 && count <= 80) {
    return "#C96ACE";
  } else if (count > 45 && count <= 60) {
    return "#81338A";
  } else if (count > 30 && count <= 45) {
    return "#641D71";
  } else if (count > 10 && count <= 30) {
    return "#491158";
  } else if (count > 5 && count <= 10) {
    return "#310B3F";
  } else if (count > 0 && count <= 5) {
    return "#1C0726";
  } else {
    return "#FFFFFF";
  }
};

export default Home;
