import { useEffect, useState } from "react";
import chartIcon from "../../assets/behaviour/chart-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import fetchSurveyData from "../../services";
import styles from "../../styles/pages/preferences/index.module.css";

const PreferencesRowTwo = ({ selectedFilters }) => {
  const [problemsData, setProblemsData] = useState([]);
  const [currentBrandData, setCurrentBrandData] = useState([]);

useEffect(() => {
const fetchData = async () => {
  try {
    const responseData = await fetchSurveyData(selectedFilters);

    if (Array.isArray(responseData) && responseData.length > 0) {
      const problemsCounts = {};
      const currentBrandCounts = {};

      responseData.forEach((entry) => {
        const { problems, current_brand } = entry;
        if (problems) {
          const problems_like = problems.split(/,\s(?!.*\))/).map(option => option.trim());
          problems_like.forEach(problem => {
            problemsCounts[problem] = (problemsCounts[problem] || 0) + 1;
          });
        }
        if (current_brand) {
          currentBrandCounts[current_brand] = (currentBrandCounts[current_brand] || 0) + 1;
        }
      });

      const problemsData = Object.entries(problemsCounts)
        .filter(([name, value]) => name !== undefined)
        .map(([name, value]) => ({ name, value }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 6);
      setProblemsData(problemsData);

      const currentBrandData = Object.entries(currentBrandCounts)
        .filter(([name, value]) => name !== undefined)
        .map(([name, value]) => ({ name, value }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 9);
      setCurrentBrandData(currentBrandData);
    } else {
      console.log("No survey data found or empty response.");
    }
  } catch (error) {
    console.error("Error fetching survey data:", error);
  }
};
fetchData();
}, [selectedFilters]);  

  return (
    <>
      <Section
        heading={
          <div className={styles.heading}>
            <img src={chartIcon} alt="" />
            <p>Which brands of sunscreen do you currently use?</p>
            <div className={styles.count} style={{ marginLeft: "260px" }}>
              Count: <b>314</b>
            </div>
          </div>
        }
        content={
          <CompareBarChart
            data={currentBrandData}
            yAxisWidth={20}
            showLegend={false}
          />
        }
        navbar={
          <div className={styles.navbar} style={{ marginLeft: "20px", marginRight: "10px" }}>
            Count:{" "}
            {currentBrandData.map((item, index) => (
              <span key={index} style={{ margin: "0px 37px 20px 20px" }}>
                {item.value}
              </span>
            ))}
          </div>
        }
        width={60}
      />
      <Section
        width={40}
        heading={
          <div className={styles.heading}>
            <img src={chartIcon} alt="" />
            <p>What are the biggest problems you have with using sunscreen?</p>
            <div className={styles.count} style={{ marginLeft: "0px" }}>
              Count: <b>314</b>
            </div>
          </div>
        }
        content={
          problemsData.length > 0 ? (
            <SimpleBarChart data={problemsData} />
          ) : (
            <div>No Data Found</div>
          )
        }
        navbar={
          <div className={styles.navbar} style={{marginBottom: "10px"}}>
            Count:{" "}
            {problemsData.map((item, index) => (
              <span
                key={index}
                style={{ marginLeft: "25px", marginRight: "25px" }}
              >
                {item.value}
              </span>
            ))}
          </div>
        }
      />
    </>
  );
};

export default PreferencesRowTwo;
