import { Button, Drawer, Space } from "antd";
import { useEffect, useState } from "react";
import chartIcon from "../../assets/behaviour/chart-icon.svg";
import horizontalChartIcon from "../../assets/behaviour/horizontalChartIcon.svg";
import filterIcon from "../../assets/home/filter-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import MobileNav from "../../components/MobileNav";
import MultiFilter from "../../components/MultiFilter";
import Sidebar from "../../components/Sidebar";
import HorizontalBarChart from "../../components/horizontalBarChart";
import fetchSurveyData from "../../services";
import styles from "../../styles/pages/behaviour/index.module.css";
import ComparisonMobile from "./MobileComponent";

// const naturalsData = require("../../data/naturalsData.json");

const Behaviours = () => {
  const [index, setIndex] = useState(0);
  const [page, setPage] = useState(1);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };

  const [sunscreenFrequencyData, setSunscreenFrequencyData] = useState([]);
  const [sunscreenApplicationData, setSunscreenApplicationData] = useState([]);
  const [sunExposureData, setSunExposureData] = useState([]);
  const [exerciseData, setExerciseData] = useState([]);
  const [sunscreenReapplyData, setSunscreenReapplyData] = useState([]);
  const [reapplyDayData, setReapplyDayData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({
    ageFilter: [],
    genderFilter: [],
    cityFilter: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData(selectedFilters);

        const exerciseCounts = {};
        const frequencyCounts = {};
        const applicationCounts = {};
        const reapplyCounts = {};
        const reapplyDayCounts = {};
        const sunExposureCounts = {};

        responseData.forEach((entry) => {
          const { exercise_frequency, sunscreen_frequency, sunscreen_apply_timing, sun_exposure, sunscreen_reapply, reapply_per_day } = entry;

          if (exercise_frequency !== undefined) {
            if (exerciseCounts[exercise_frequency]) {
              exerciseCounts[exercise_frequency]++;
            } else {
              exerciseCounts[exercise_frequency] = 1;
            }
          }

          if (sunscreen_frequency !== undefined) {
            if (frequencyCounts[sunscreen_frequency]) {
              frequencyCounts[sunscreen_frequency]++;
            } else {
              frequencyCounts[sunscreen_frequency] = 1;
            }
          }

          if (sunscreen_apply_timing !== undefined) {
            if (applicationCounts[sunscreen_apply_timing]) {
              applicationCounts[sunscreen_apply_timing]++;
            } else {
              applicationCounts[sunscreen_apply_timing] = 1;
            }
          }

          if (sunscreen_reapply !== undefined) {
            if (reapplyCounts[sunscreen_reapply]) {
              reapplyCounts[sunscreen_reapply]++;
            } else {
              reapplyCounts[sunscreen_reapply] = 1;
            }
          }

          if (reapply_per_day !== undefined) {
            if (reapplyDayCounts[reapply_per_day]) {
              reapplyDayCounts[reapply_per_day]++;
            } else {
              reapplyDayCounts[reapply_per_day] = 1;
            }
          }

          if (sun_exposure !== undefined) {
            if (sunExposureCounts[sun_exposure]) {
              sunExposureCounts[sun_exposure]++;
            } else {
              sunExposureCounts[sun_exposure] = 1;
            }
          }
        });

        const exerciseData = Object.entries(exerciseCounts)
          .map(([name, value]) => ({ name, value }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 5);
        const sunscreenFrequencyData = Object.entries(frequencyCounts)
          .map(([name, value]) => ({ name, value }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 5);
        const sunscreenApplicationData = Object.entries(applicationCounts)
          .map(([name, value]) => ({ name, value }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 5);
        const sunscreenReapplyData = Object.entries(reapplyCounts)
          .map(([name, value]) => ({ name, value }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 5);
        const reapplyDayData = Object.entries(reapplyDayCounts)
          .map(([name, value]) => ({ name, value }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 5);
        const sunExposureData = Object.entries(sunExposureCounts)
          .map(([name, value]) => ({ name, value }))
          .sort((a, b) => b.value - a.value)
          .slice(0, 5);

        setExerciseData(exerciseData);
        setSunExposureData(sunExposureData);
        setSunscreenFrequencyData(sunscreenFrequencyData);
        setSunscreenApplicationData(sunscreenApplicationData);
        setSunscreenReapplyData(sunscreenReapplyData);
        setReapplyDayData(reapplyDayData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedFilters]);


  // useEffect(() => {
  //   const processExerciseData = () => {
  //     const exerciseCounts = {};
  //     let filteredData = naturalsData;

  //     if (selectedFilters.ageFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.ageFilter.includes(entry.age)
  //       );
  //     }

  //     if (selectedFilters.genderFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.genderFilter.includes(entry.gender)
  //       );
  //     }

  //     if (selectedFilters.cityFilter.length > 0) {
  //       if (selectedFilters.cityFilter.includes("Metropolitan")) {
  //         filteredData = filteredData.filter((entry) =>
  //           metropolitanCities.includes(entry.city)
  //         );
  //       } else if (selectedFilters.cityFilter.includes("Non-Metropolitan")) {
  //         filteredData = filteredData.filter(
  //           (entry) => !metropolitanCities.includes(entry.city)
  //         );
  //       }
  //     }

  //     filteredData.forEach((entry) => {
  //       const { exercise_frequency } = entry;
  //       if (exerciseCounts[exercise_frequency]) {
  //         exerciseCounts[exercise_frequency]++;
  //       } else {
  //         exerciseCounts[exercise_frequency] = 1;
  //       }
  //     });

  //     const exerciseArray = Object.entries(exerciseCounts).map(
  //       ([name, value]) => ({ name, value })
  //     );

  //     const sortedExerciseArray = exerciseArray.sort(
  //       (a, b) => b.value - a.value
  //     );
  //     setExerciseData(sortedExerciseArray);
  //   };

  //   const processSunscreenFrequencyData = () => {
  //     const sunscreenFrequencyCounts = {};
  //     let filteredData = naturalsData;

  //     if (selectedFilters.ageFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.ageFilter.includes(entry.age)
  //       );
  //     }

  //     if (selectedFilters.genderFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.genderFilter.includes(entry.gender)
  //       );
  //     }

  //     if (selectedFilters.cityFilter.length > 0) {
  //       if (selectedFilters.cityFilter.includes("Metropolitan")) {
  //         filteredData = filteredData.filter((entry) =>
  //           metropolitanCities.includes(entry.city)
  //         );
  //       } else if (selectedFilters.cityFilter.includes("Non-Metropolitan")) {
  //         filteredData = filteredData.filter(
  //           (entry) => !metropolitanCities.includes(entry.city)
  //         );
  //       }
  //     }

  //     filteredData.forEach((entry) => {
  //       const { sunscreen_frequency } = entry;
  //       if (sunscreenFrequencyCounts[sunscreen_frequency]) {
  //         sunscreenFrequencyCounts[sunscreen_frequency]++;
  //       } else {
  //         sunscreenFrequencyCounts[sunscreen_frequency] = 1;
  //       }
  //     });

  //     const sunscreenFrequencyArray = Object.entries(
  //       sunscreenFrequencyCounts
  //     ).map(([name, value]) => ({
  //       name,
  //       value,
  //     }));

  //     const sortedSunscreenFrequencyArray = sunscreenFrequencyArray.sort(
  //       (a, b) => b.value - a.value
  //     );
  //     setSunscreenFrequencyData(sortedSunscreenFrequencyArray);
  //   };

  //   const processSunscreenApplicationData = () => {
  //     const sunscreenApplicationCounts = {};
  //     let filteredData = naturalsData;

  //     if (selectedFilters.ageFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.ageFilter.includes(entry.age)
  //       );
  //     }

  //     if (selectedFilters.genderFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.genderFilter.includes(entry.gender)
  //       );
  //     }

  //     if (selectedFilters.cityFilter.length > 0) {
  //       if (selectedFilters.cityFilter.includes("Metropolitan")) {
  //         filteredData = filteredData.filter((entry) =>
  //           metropolitanCities.includes(entry.city)
  //         );
  //       } else if (selectedFilters.cityFilter.includes("Non-Metropolitan")) {
  //         filteredData = filteredData.filter(
  //           (entry) => !metropolitanCities.includes(entry.city)
  //         );
  //       }
  //     }

  //     filteredData.forEach((entry) => {
  //       const { sunscreen_apply_timing } = entry;
  //       if (sunscreenApplicationCounts[sunscreen_apply_timing]) {
  //         sunscreenApplicationCounts[sunscreen_apply_timing]++;
  //       } else {
  //         sunscreenApplicationCounts[sunscreen_apply_timing] = 1;
  //       }
  //     });

  //     const sunscreenApplicationArray = Object.entries(
  //       sunscreenApplicationCounts
  //     ).map(([name, value]) => ({
  //       name,
  //       value,
  //     }));

  //     const sortedSunscreenApplicationArray = sunscreenApplicationArray.sort(
  //       (a, b) => b.value - a.value
  //     );
  //     setSunscreenApplicationData(sortedSunscreenApplicationArray);
  //   };

  //   const processSunExposureData = () => {
  //     const sunExposureCounts = {};
  //     let filteredData = naturalsData;

  //     if (selectedFilters.ageFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.ageFilter.includes(entry.age)
  //       );
  //     }

  //     if (selectedFilters.genderFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.genderFilter.includes(entry.gender)
  //       );
  //     }

  //     if (selectedFilters.cityFilter.length > 0) {
  //       if (selectedFilters.cityFilter.includes("Metropolitan")) {
  //         filteredData = filteredData.filter((entry) =>
  //           metropolitanCities.includes(entry.city)
  //         );
  //       } else if (selectedFilters.cityFilter.includes("Non-Metropolitan")) {
  //         filteredData = filteredData.filter(
  //           (entry) => !metropolitanCities.includes(entry.city)
  //         );
  //       }
  //     }

  //     filteredData.forEach((entry) => {
  //       const { sun_exposure } = entry;
  //       if (sunExposureCounts[sun_exposure]) {
  //         sunExposureCounts[sun_exposure]++;
  //       } else {
  //         sunExposureCounts[sun_exposure] = 1;
  //       }
  //     });

  //     const sunExposureArray = Object.entries(sunExposureCounts).map(
  //       ([name, value]) => ({
  //         name,
  //         value,
  //       })
  //     );

  //     const sortedsunExposureArray = sunExposureArray.sort(
  //       (a, b) => b.value - a.value
  //     );
  //     setSunExposureData(sortedsunExposureArray);
  //   };

  //   const processSunscreenReapplyData = () => {
  //     const sunscreenReapplyCounts = {};

  //     let filteredData = naturalsData;

  //     if (selectedFilters.ageFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.ageFilter.includes(entry.age)
  //       );
  //     }

  //     if (selectedFilters.genderFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.genderFilter.includes(entry.gender)
  //       );
  //     }

  //     if (selectedFilters.cityFilter.length > 0) {
  //       if (selectedFilters.cityFilter.includes("Metropolitan")) {
  //         filteredData = filteredData.filter((entry) =>
  //           metropolitanCities.includes(entry.city)
  //         );
  //       } else if (selectedFilters.cityFilter.includes("Non-Metropolitan")) {
  //         filteredData = filteredData.filter(
  //           (entry) => !metropolitanCities.includes(entry.city)
  //         );
  //       }
  //     }

  //     filteredData.forEach((entry) => {
  //       const { sunscreen_reapply } = entry;
  //       if (sunscreenReapplyCounts[sunscreen_reapply]) {
  //         sunscreenReapplyCounts[sunscreen_reapply]++;
  //       } else {
  //         sunscreenReapplyCounts[sunscreen_reapply] = 1;
  //       }
  //     });

  //     const sunscreenReapplyArray = Object.entries(sunscreenReapplyCounts).map(
  //       ([name, value]) => ({ name, value })
  //     );

  //     const sortedSunscreenReapplyData = sunscreenReapplyArray.sort(
  //       (a, b) => b.value - a.value
  //     );
  //     setSunscreenReapplyData(sortedSunscreenReapplyData);
  //   };

  //   const processReapplyPerDayData = () => {
  //     const reapplyDayCounts = {};
  //     let filteredData = naturalsData;

  //     if (selectedFilters.ageFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.ageFilter.includes(entry.age)
  //       );
  //     }

  //     if (selectedFilters.genderFilter.length > 0) {
  //       filteredData = filteredData.filter((entry) =>
  //         selectedFilters.genderFilter.includes(entry.gender)
  //       );
  //     }

  //     if (selectedFilters.cityFilter.length > 0) {
  //       if (selectedFilters.cityFilter.includes("Metropolitan")) {
  //         filteredData = filteredData.filter((entry) =>
  //           metropolitanCities.includes(entry.city)
  //         );
  //       } else if (selectedFilters.cityFilter.includes("Non-Metropolitan")) {
  //         filteredData = filteredData.filter(
  //           (entry) => !metropolitanCities.includes(entry.city)
  //         );
  //       }
  //     }

  //     filteredData.forEach((entry) => {
  //       const { reapply_per_day } = entry;
  //       if (reapplyDayCounts[reapply_per_day]) {
  //         reapplyDayCounts[reapply_per_day]++;
  //       } else {
  //         reapplyDayCounts[reapply_per_day] = 1;
  //       }
  //     });

  //     const reapplyDayArray = Object.entries(reapplyDayCounts).map(
  //       ([name, value]) => ({ name, value })
  //     );

  //     const sortedReapplyDayArray = reapplyDayArray.sort(
  //       (a, b) => b.value - a.value
  //     );
  //     setReapplyDayData(sortedReapplyDayArray);
  //   };

  //   processExerciseData();
  //   processSunscreenFrequencyData();
  //   processSunscreenApplicationData();
  //   processSunExposureData();
  //   processSunscreenReapplyData();
  //   processReapplyPerDayData();
  // }, [selectedFilters]);

  const handleUpdateFilter = (filters) => {
    setSelectedFilters(filters);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.container}>
      <Sidebar handleChange={handleChange} />
      <MobileNav />
      <div className={styles.bodyContainer}>
        <ComparisonMobile />
        <div className={styles.subBodyContainer}>
          {page === 1 && (
            <div className="hide-in__mobile">
              <div
                className={styles.topBar}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <div style={{ flex: 1 }}>
                  <h2 style={{ display: "inline-block" }}>Behaviours</h2>
                </div>
                <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={showDrawer}
              >
                <img src={filterIcon} height={35} alt="" />
              </div>
            </div>
            <Drawer
              placement="right"
              width="auto"
              onClose={onClose}
              open={open}
              extra={
                <Space>
                  <Button onClick={onClose}>Close</Button>
                  <Button type="primary">Apply Filter</Button>
                </Space>
              }
            >
              <MultiFilter handleUpdateFilter={handleUpdateFilter} />
            </Drawer>
              {/* Row one */}
              <div className={styles.brandsSelectContainer}>
                <Section
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>How often do you exercise?</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "0px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    <CompareBarChart
                      data={exerciseData}
                      yAxisWidth={20}
                      showLegend={false}
                    />
                  }
                  navbar={
                    <div
                      className={styles.navbar}
                    >
                      Count:{" "}
                      {exerciseData.map((item, index) => (
                        <span
                          key={index}
                          style={{ margin: "0px 20px 20px 20px" }}
                        >
                          {item.value}
                        </span>
                      ))}
                    </div>
                  }
                  width={28}
                />

                <Section
                  width={38}
                  heading={
                    <div className={styles.heading}>
                      <img src={horizontalChartIcon} alt="" />
                      <p>How long are you exposed to sun in a day?</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "40px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    <div style={{ display: "flex" }}>
                      {" "}
                      <div
                        className={styles.navbar}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginTop: "5px",
                          marginRight: "0px",
                        }}
                      >
                        Count:{" "}
                        {sunExposureData.map((item, index) => (
                          <span
                            key={index}
                            style={{
                              margin: "auto",
                            }}
                          >
                            {item.value}
                          </span>
                        ))}
                      </div>
                      {sunExposureData.length > 0 ? (
                        <HorizontalBarChart
                          data={sunExposureData}
                          truncateLength={15}
                        />
                      ) : (
                        <div>No Data Found</div>
                      )}
                    </div>
                  }
                />

                <Section
                  width={32}
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>How frequently do you use sunscreen?</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "0px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    sunscreenFrequencyData.length > 0 ? (
                      <SimpleBarChart data={sunscreenFrequencyData} />
                    ) : (
                      <div>No Data Found</div>
                    )
                  }
                  navbar={
                    <div className={styles.navbar}>
                      Count:{" "}
                      {sunscreenFrequencyData.map((item, index) => (
                        <span
                          key={index}
                          style={{ marginLeft: "18px", marginRight: "20px" }}
                        >
                          {item.value}
                        </span>
                      ))}
                    </div>
                  }
                />
              </div>
              {/* Row two */}
              <div className={styles.brandsSelectContainer}>
                <Section
                  width={33}
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>When do you typically apply sunscreen?</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "0px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    sunscreenApplicationData.length > 0 ? (
                      <SimpleBarChart
                        truncateLength={13}
                        data={sunscreenApplicationData}
                        // barColor="#27272A"
                      />
                    ) : (
                      <div>No Data Found</div>
                    )
                  }
                  navbar={
                    <div className={styles.navbar}>
                      Count:{" "}
                      {sunscreenApplicationData.map((item, index) => (
                        <span
                          key={index}
                          style={{ marginLeft: "20px", marginRight: "30px" }}
                        >
                          {item.value}
                        </span>
                      ))}
                    </div>
                  }
                />

                <Section
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>
                        How many times do you reapply your sunscreen in a day?
                      </p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "0px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    <CompareBarChart
                      data={sunscreenReapplyData}
                      yAxisWidth={20}
                      showLegend={false}
                    />
                  }
                  navbar={
                    <div
                      className={styles.navbar}
                      style={{ marginRight: "5px" }}
                    >
                      Count:{" "}
                      {sunscreenReapplyData.map((item, index) => (
                        <span
                          key={index}
                          style={{ margin: "0px 25px 20px 35px" }}
                        >
                          {item.value}
                        </span>
                      ))}
                    </div>
                  }
                  width={34}
                />

                <Section
                  heading={
                    <div className={styles.heading}>
                      <img src={chartIcon} alt="" />
                      <p>How often do you reapply sunscreen in a day?</p>
                      <div
                        className={styles.count}
                        style={{ marginLeft: "0px" }}
                      >
                        Count: <b>314</b>
                      </div>
                    </div>
                  }
                  content={
                    <CompareBarChart
                      data={reapplyDayData}
                      yAxisWidth={20}
                      showLegend={false}
                      // barColor="#27272A"
                    />
                  }
                  navbar={
                    <div className={styles.navbar}>
                      Count:{" "}
                      {reapplyDayData.map((item, index) => (
                        <span
                          key={index}
                          style={{ margin: "0px 20px 20px 16px" }}
                        >
                          {item.value}
                        </span>
                      ))}
                    </div>
                  }
                  width={32}
                />
              </div>{" "}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Behaviours;
