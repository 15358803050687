import axios from "axios";
import { baseUrl, baseUrlLocal } from "../utils/baseURL";

const fetchSurveyData = async (selectedFilters) => {
  try {
    const response = await axios.get(
      `${baseUrl}/naturals/dashboard-data?form_id=TN6EZwZV`,
      {
        params: {
          ...selectedFilters,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching survey data:", error);
    throw error;
  }
};

export default fetchSurveyData;
