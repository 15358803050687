import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NotFound from "./components/404";
import Sunscreen from "./pages/sunscreens";
import Behaviours from "./pages/behaviours";
import Prefrences from "./pages/preferences";
import Home from "./pages/home";

const App = () => {
  return (
    <Router>
      {/* <Navbar /> */}
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Home />} />
        <Route path="/behaviours" element={<Behaviours />} />
        <Route path="/preferences" element={<Prefrences />} />
        <Route path="/sunscreen" element={<Sunscreen />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
