import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const SimpleBarChart = ({ data, barColor, truncateLength }) => {
  const customTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div className="custom-tooltip">
          {active && (
            <>
              <p style={{ fontSize: "15px", fontWeight: 400, opacity: 0.7 }}>
                Name: 
                {truncateLabel(data.name, truncateLength)}
              </p>
              <p style={{ fontSize: "15px", fontWeight: 400, opacity: 0.7 }}>
                Value: 
                {truncateLabel(data.value, truncateLength)}
              </p>
            </>
          )}
        </div>
      );
    }
    return null;
  };

  const customXAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, truncateLength);
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={12} fontSize={11} textAnchor="middle" fill="#666">
          {truncatedLabel}
        </text>
      </g>
    );
  };

  const customYAxisTick = (props) => {
    const { x, y, payload } = props;
    const truncatedLabel = truncateLabel(payload.value, truncateLength);
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={4} fontSize={11} textAnchor="end" fill="#666">
          {truncatedLabel}
        </text>
      </g>
    );
  };

  const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      const truncatedLabels = [];
      for (let i = 0; i < label.length; i += maxLength) {
        truncatedLabels.push(
          <tspan x="0" dy="1.2em" key={i}>
            {label.substring(i, i + maxLength)}
          </tspan>
        );
      }
      return truncatedLabels;
    }
    return label;
  };

  return (
    <ResponsiveContainer width="100%">
      <BarChart data={data} margin={{ top: 10, right: 10, left: 20, bottom: 10 }}>
        <CartesianGrid stroke="#f5f5f6" />
        <XAxis tick={customXAxisTick} interval={0} dataKey="name" />
        <YAxis width={22} tick={customYAxisTick} interval={0} />
        <Tooltip content={customTooltip} />
        <Bar
          dataKey="value"
          fill={barColor || "#A1A1AA"}
          radius={[8, 8, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default SimpleBarChart;
