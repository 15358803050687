// Prefrences.js
import { useState } from "react";
import styles from "../../styles/pages/preferences/index.module.css";
import Sidebar from "../../components/Sidebar";
import PreferencesRowOne from "./PreferencesRowOne";
import PreferencesRowTwo from "./PreferencesRowTwo";
import PreferencesRowThree from "./PreferencesRowThree";
import PreferencesRowFour from "./PreferencesRowFour";
import { Button, Drawer, Space } from "antd";
import filterIcon from "../../assets/home/filter-icon.svg";
import MultiFilter from "../../components/MultiFilter";

const Prefrences = () => {
const [index, setIndex] = useState(0);
  const handleChange = (e) => {
    setIndex(Number(e) - 1);
  };

  const [selectedFilters, setSelectedFilters] = useState({
    ageFilter: [],
    genderFilter: [],
    cityFilter: [],
  });

  const handleUpdateFilter = (filters) => {
    setSelectedFilters(filters);
  };

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.container}>
      <Sidebar handleChange={handleChange} />
      <div className={styles.bodyContainer}>
        <div className={styles.subBodyContainer}>
          <div
            className={styles.topBar}
            style={{ display: "flex", flexDirection: "row" }}
          >
            <div style={{ flex: 1 }}>
              <h2 style={{ display: "inline-block" }}>Preferences</h2>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={showDrawer}
            >
              <img src={filterIcon} height={35} alt="" />
            </div>
          </div>

          <Drawer
            placement="right"
            width="auto"
            onClose={onClose}
            open={open}
            extra={
              <Space>
                <Button onClick={onClose}>Close</Button>
                <Button type="primary">Apply Filter</Button>
              </Space>
            }
          >
            <MultiFilter handleUpdateFilter={handleUpdateFilter} />
          </Drawer>

          <div className={styles.brandsSelectContainer}>
            <PreferencesRowOne
              selectedFilters={selectedFilters} 
            />
          </div>
          <div className={styles.brandsSelectContainer}>
            <PreferencesRowTwo
              selectedFilters={selectedFilters} 
            />
          </div>
          <div className={styles.brandsSelectContainer}>
            <PreferencesRowThree
              selectedFilters={selectedFilters} 
            />
          </div>
          <div className={styles.brandsSelectContainer}>
            <PreferencesRowFour
              selectedFilters={selectedFilters} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prefrences;
