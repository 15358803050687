import React, { useEffect, useState } from "react";
import chartIcon from "../../assets/behaviour/chart-icon.svg";
import SimpleBarChart from "../../components/BarChart";
import Section from "../../components/BrandComparisonSection";
import CompareBarChart from "../../components/CompareBarChart";
import fetchSurveyData from "../../services";
import styles from "../../styles/pages/preferences/index.module.css";
const otherFeatureSunscreenData = require("../../data/preferences/otherFeatureSunscreenData.json");

const PreferencesRowFour = ({ selectedFilters }) => {
  const [featuresWantedData, setFeaturesWantedData] = useState([]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await fetchSurveyData(selectedFilters);
    
        if (Array.isArray(responseData) && responseData.length > 0) {
          const featuresWantedCounts = {};
    
          responseData.forEach((entry) => {
            const { features_wanted } = entry;
            if (features_wanted) {
              const features = features_wanted.split(/,\s(?!.*\))/).map(option => option.trim());
              features.forEach(feature => {
                featuresWantedCounts[feature] = (featuresWantedCounts[feature] || 0) + 1;
              });
            }
          });
    
          const featuresWantedData = Object.entries(featuresWantedCounts)
            .filter(([name, value]) => name !== undefined)
            .map(([name, value]) => ({ name, value }))
            .sort((a, b) => b.value - a.value)
            .slice(0, 9);
          setFeaturesWantedData(featuresWantedData);
        } else {
          console.log("No survey data found or empty response.");
        }
      } catch (error) {
        console.error("Error fetching survey data:", error);
      }
    };
    fetchData();
  }, [selectedFilters]);

  return (
    <>
      <Section
        heading={
          <div className={styles.heading}>
            <img src={chartIcon} alt="" />
            <p>
              Are there any features you would like to see in future sunscreens?
            </p>
            <div className={styles.count} style={{ marginLeft: "160px" }}>
              Count: <b>314</b>
            </div>
          </div>
        }
        content={
          <CompareBarChart
            data={featuresWantedData}
            yAxisWidth={20}
            showLegend={false}
          />
        }
        navbar={
          <div
            className={styles.navbar}
            style={{ marginLeft: "10px", marginRight: "0px" }}
          >
            Count:{" "}
            {featuresWantedData.map((item, index) => (
              <span key={index} style={{ margin: "0px 45px 20px 7px" }}>
                {item.value}
              </span>
            ))}
          </div>
        }
        width={60}
      />
      <Section
        width={40}
        heading={
          <div className={styles.heading}>
            <img src={chartIcon} alt="" />
            <p>
              Are there any features you would like to see in future sunscreens?
              (Others)
            </p>
            <div className={styles.count} style={{ marginLeft: "0px" }}>
              Count: <b>17</b>
            </div>
          </div>
        }
        content={
          otherFeatureSunscreenData.length > 0 ? (
            <SimpleBarChart
              data={otherFeatureSunscreenData}
              truncateLength={8}
            />
          ) : (
            <div>No Data Found</div>
          )
        }
        navbar={
          <div className={styles.navbar} style={{ marginBottom: "10px" }}>
            Count:{" "}
            {otherFeatureSunscreenData.map((item, index) => (
              <span
                key={index}
                style={{
                  marginLeft: "18px",
                  marginRight: "20px",
                }}
              >
                {item.value}
              </span>
            ))}
          </div>
        }
      />
    </>
  );
};

export default PreferencesRowFour;
